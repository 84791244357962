// 3つの数字毎にカンマ区切りで表示する
import { isNullish } from 'js/utils/helper';

export const pointFormat = (point: number) => {
  return point.toString().replace(/([\d]+?)(?=(?:\d{3})+$)/g, (t) => {
    return t + ',';
  });
};

export const getPoints = (point1?: number | null, point2?: number | null) => {
  const points = [];
  if (!isNullish(point1)) {
    points.push(pointFormat(point1));
  }
  if (!isNullish(point2)) {
    points.push(pointFormat(point2));
  }

  // 0の場合は空配列を返す
  if (
    (points.length === 1 && points[0] === '0') ||
    (points.length === 2 && points[0] === '0' && points[1] === '0')
  ) {
    return [];
  }

  return points;
};
